import React, { Component } from 'react'
import { graphql } from 'gatsby'
import Helmet from 'react-helmet'
import elasticlunr from 'elasticlunr'
import 'url-search-params-polyfill'

import Tile from '../components/archive/tile'

class SearchPage extends Component {


  constructor(props) {
    super(props)

    const urlParams = new URLSearchParams(this.props.location.search)

    this.state = {
      location: this.props.location,
      query: urlParams.get('query') || '',
      results: this.props.data.allWordpressPost.edges.map(el => el.node).slice(0, 16) || [],
    }
  }

  componentDidMount() {
    this.index = elasticlunr()
    this.index.addField('title')
    this.index.addField('excerpt')
    this.index.addField('keywords')
    this.index.addField('author')
    this.index.setRef('id')

    this.props.data.allWordpressPost.edges.forEach((el) => {
      let keywords = el.node.tags || []
      el.node.keywords = keywords.map(t => t.name).join(' ')
      el.node.excerpt = el.node.acf.excerpt
      el.node.author = el.node.acf.author ? el.node.acf.author.map(a => a.post_title).join(' ') : ''
      this.index.addDoc(el.node)
    })

    if (this.state.query) {
      this.setState({
        results: this.index.search(this.state.query)
          .map(({ ref }) => this.index.documentStore.getDoc(ref)),
      })
    }
  }

  static getDerivedStateFromProps(nextProps, prevState){
    if (nextProps.location.search !== prevState.location.search) {
       return { location: nextProps.location }
    } else {
      return null
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.location.search !== this.props.location.search) {
      //Perform some operation here
      const urlParams = new URLSearchParams(this.props.location.search)
      this.setState({
        query: urlParams.get('query') || '',
        results: this.index.search(urlParams.get('query'))
          .map(({ ref }) => this.index.documentStore.getDoc(ref)),
      })
    }
  }

  _search = (event) => {
    const query = event.target.value

    this.setState({
      query,
      // Query the index with search string to get an [] of IDs
      results: this.index.search(query)
      // Map over each ID and return the full document
      .map(({ ref }) => this.index.documentStore.getDoc(ref)),
    })
  }

  _renderResult = () => (item, i) => {
    item.feature_image = item.acf && item.acf.featured_image.url

    return <Tile key={i} {...item} />
  }

  render() {
    return (
      <>
        <Helmet>
          <body />
        </Helmet>
        <form className='search__form'>
          <label htmlFor='query'>Search</label>
          <input type='text' id='query' name='query' placeholder='Start Searching' onChange={this._search} value={this.state.query} />
        </form>
        <div className='search__results'>
          { this.state.results.map(this._renderResult()) }
        </div>
      </>
    )
  }
}

export default SearchPage

export const searchQuery = graphql`
  query {
    allWordpressPost {
      edges {
        node {
          id
          title
          slug
          tags {
            name
          }
          acf {
            excerpt
            author {
              post_title
            }
            type
            featured_image {
              url
              alt
            }
          }
        }
      }
    }
  }
`
